@import "../../style.scss";
@import "../../wp-style.scss";

.ferienwohnung,
.entdecken {

    .container {
        display: flex;
        flex-direction: column;
        gap: 10vh;
    }

    .container {
        align-items: center;

        .ferienwohnungen {
            display: flex;
            flex-direction: column;
            gap: 20vh;
            width: inherit;

            .item {
                display: flex;
                flex-direction: column;
                position: relative;
                width: inherit;
                text-transform: initial;

                &:nth-child(2) {

                    .top {
                        flex-direction: row-reverse;

                        @include mobile {
                            flex-direction: column;
                        }
                    }

                    h2 {
                        left: 10%;
                    }
                }

                .top {
                    display: flex;
                    gap: 5vw;

                    @include mobile {
                        flex-direction: column;
                    }

                    img {
                        width: 70%;
                        transition: 0.5s ease-in-out;
                        object-fit: cover;

                        &:hover {
                            transform: scale(1.05);
                        }
                    }

                    .right {
                        display: flex;
                        flex-direction: column;

                        span {
                            color: $accent;
                        }

                        p {
                            margin-top: 20px;
                            margin-bottom: 25vh;
                            color: $textColor;
                        }
                    }
                }

                h2 {
                    font-size: 4rem;
                    position: absolute;
                    color: $textColor;
                    right: 10%;
                    top: 80%;

                    @include mobile {
                        font-size: 2rem;
                        top: 15%;
                        right: 0%;
                        text-align: right;
                    }
                }
            }
        }
    }

    .description {
        margin-top: 10vh;
    }
}