@import "../../style.scss";

.nav {
    position: fixed;
    height: auto;
    width: 100%;
    z-index: 30;

    .navbar {
        display: flex;
        align-items: center;
        background: $bg;
        height: 200px;
        transition: 0.3s ease-in-out;

        @include mobile {
            height: 100px;
        }

        .container {
            flex-direction: row;
            justify-content: space-between;

            .left {
                display: flex;
                width: 1px;

                .openNav {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    gap: 7px;
                    height: inherit;
                    cursor: pointer;
                    z-index: 30;

                    .navLines {
                        transition: 0.1s ease-in-out;
                        background: currentColor;
                        height: 1px;
                        width: 50px;
                        z-index: 30;
                    }

                    .navLines:last-child {
                        width: 30px;
                    }
                }

                .openNav.active {
                    .navLines {
                        background: $accent;
                        transform: rotate(45deg) translate(1px, 5px);
                    }

                    .navLines:last-child {
                        width: 50px;
                        transform: rotate(-45deg) translate(1px, -5px);
                    }
                }
            }

            .logoLink {
                fill: $textColor;

                .logo {
                    width: auto;
                    height: 100px;
                    transition: 0.2s ease-in-out;
                    text-transform: inherit;
                    text-align: center;

                    @include mobile {
                        height: 50px;
                    }

                    h2 {
                        color: inherit;
                        height: auto;
                        transition: 0.2s ease-in-out;
                    }

                    span {
                        display: flex;
                        transition: 0.2s ease-in-out;
                        color: $accent;
                    }
                }
            }

            .right {
                display: flex;
                justify-content: flex-end;
                width: 1px;
                gap: 50px;

                .links {
                    display: flex;
                    gap: 30px;
                    transition: 0.2s ease-in-out;

                    @include desktop {
                        display: none;
                    }

                    .call {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                    }
                }

                .language {
                    display: flex;
                    align-items: center;
                    height: 100%;

                    .boxLanguage {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        height: 30px;
                        width: 30px;
                        padding: 10px;
                        gap: 10px;
                        transition: 0.2s ease-in-out;

                        button {
                            background: none;
                            text-align: center;
                            padding: 0;
                            text-transform: uppercase;
                            color: inherit;
                        }

                        .expand {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            position: absolute;
                            width: 30px;
                            padding: 10px;
                            padding-top: 20px;
                            background: $accent;
                            top: 40px;
                            gap: 10px;
                            opacity: 0;

                            button {
                                color: $bg;
                            }
                        }

                        .expand.active {
                            opacity: 1;
                        }
                    }

                    .boxLanguage.active {
                        background: $accent;
                    }
                }

                a {
                    color: currentColor;
                    text-decoration: none;
                    font-size: 1.2rem;
                    text-transform: uppercase;
                    transition: 0.2s ease-in-out;

                    &:hover {
                        color: $accent;
                    }
                }
            }
        }
    }

    .navbar.scroll {
        height: 70px;
        color: $textColor !important;

        .container {

            .logo {
                width: auto;
                height: 40px;

                img {
                    height: inherit;
                }

                h2 {
                    height: 0;
                    overflow: hidden;
                }

                span {
                    height: 0;
                    overflow: hidden;
                }
            }
        }
    }

    .navbar.active {
        background: $bgAccent;
        color: $bg;

        .logoLink {
            fill: $bg;
        }
    }

    .navModal {
        height: 0;
        padding-top: 80px;
        overflow: hidden;
        transition: 0.2s ease-in-out;

        .container {
            display: flex;

            .left {
                display: flex;
                flex-direction: column;
                padding: 5%;
                gap: 20px;

                a {
                    color: $bg;
                    transition: 0.2s ease-in-out;

                    &:hover {
                        color: $accent;
                    }
                }
            }
        }
    }

    .navModal.open {
        height: calc(100vh - 200px);
        background: $bgAccent;

        @include mobile {
            height: calc(100vh - 150px);
        }
    }
}