@import "../../../style.scss";

.viewFerienwohnung {

    .container {
        display: flex;
        flex-direction: column;
        gap: 10vh;
    }

    .summary {
        display: flex;
        gap: 5vw;

        @include mobile {
            flex-direction: column;
        }

        .left {
            display: flex;
            flex-direction: column;
            width: 40%;

            @include mobile {
                width: 100%;
            }

            p {
                margin-bottom: 20px;
            }

            a {
                margin-top: 50px;
            }

            img {
                margin-top: 10vh;
                width: 100%;
                aspect-ratio: 1;
                object-fit: cover;
            }
        }

        .right {
            width: 60%;

            @include mobile {
                width: 100%;
            }

            img {
                width: 100%;
                aspect-ratio: 3 / 4;
                object-fit: cover;
            }
        }
    }

    .wp-content {

        .wp-block-columns {
            flex-direction: row;

            @include mobile {
                flex-direction: column;
                gap: 10px;
            }
        }

        .wp-block-gallery {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
            gap: 15px;
            margin: auto;

            .wp-block-image {
                max-width: 90vw;
            }
        }

        section {
            background: $bgLight;
            padding: 5vh;

            .is-layout-flow {
                display: flex;
                flex-direction: column;
                width: 50%;
                margin-top: 5vh;

                .wp-block-button {
                    margin-top: 20px;
                }
            }
        }
    }
}