@import "../../style.scss";

.entdecken {
    display: block;

    .beschreibung {

        .container {
            gap: 20px;
            margin-bottom: 10vh;

            h3 {
                margin-bottom: 20px;
            }
        }
    }
}