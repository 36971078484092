@import "../../style.scss";

.home {

    .header-start {
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;
        width: 100vw;
        margin: 0 auto;
        height: 100vh;
        overflow: hidden;

        .container {
            display: flex;
            justify-content: center;
            padding-left: 10vw;
            padding-right: 10vw;
            height: 50vh;
            max-width: calc(1920px - 5vw);
            margin-top: 40vh;
            color: $textColor;
            z-index: 10;

            h1 {
                max-width: 600px;
                margin-bottom: 20px;
                color: $bg;
            }

            .bottomLeft {
                display: flex;
                max-width: 90%;
                bottom: 10%;
                gap: 20px;
                left: 5%;
                z-index: 1;

                a {
                    text-decoration: none;
                }
            }
        }

        .arrows {
            display: flex;
            position: absolute;
            right: 5%;
            bottom: 5vh;
            gap: 20px;
            z-index: 10;
        }

        .slideshow {
            margin-top: 250px;
            position: absolute;
            width: 90vw;
            max-width: 1920px;
            height: calc(90vh - 200px);
            background: $textColor;

            @include mobile {
                margin-top: 120px;
                height: calc(90vh - 50px);
            }

            .slick-slider {
                height: inherit;

                .slick-list {
                    height: inherit;
                    opacity: 0.75;
                }

                .slick-track {
                    height: inherit;

                    div {
                        height: inherit;
                    }

                    img,
                    video {
                        width: 100vw;
                        height: inherit;
                        aspect-ratio: 16 / 9;
                        object-fit: cover;
                        z-index: 0;
                    }
                }

                .arrow {
                    display: flex;
                    position: absolute;
                    bottom: 5vh;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 50px;
                    cursor: pointer;
                    border: 1px solid $bg;
                    transition: 0.2s ease-in-out;
                    z-index: 10;
                }

                .arrow.next {
                    right: 5%;
                    background: $accent;
                    border-color: $accent;
                    color: $bg;

                    &:hover {
                        background: $bg;
                        border-color: $bg;
                        color: $accent;
                    }
                }

                .arrow.prev {
                    right: calc(5% + 70px);
                    color: $bg;

                    &:hover {
                        background: $bg;
                        color: $accent;
                    }
                }
            }
        }
    }

    .vacation,
    .ferienwohnung {
        display: flex;
        position: relative;
        margin-top: 20vh;
        gap: 5vw;

        @include mobile {
            flex-direction: column;
            margin-top: 100px;
        }

        .left {
            width: 60%;

            @include mobile {
                width: 100%;
            }

            h2 {
                margin-left: 5vw;
                margin-bottom: 20px;
            }

            p {
                margin-left: 5vw;
                margin-bottom: 5vh;
            }

            img {
                width: 100%;
            }
        }

        .imageLeft {
            width: 30vw;
            height: fit-content;
            object-fit: cover;
            aspect-ratio: 4 / 5;

            @include mobile {
                width: 90%;
                margin: 0 5vw;
            }
        }

        .right {
            position: absolute;
            aspect-ratio: 1;
            width: 300px;
            height: auto;
            padding: 30px;
            left: 50vw;
            top: 50%;
            z-index: 10;
            background: $accent;

            @include mobile {
                position: relative;
                left: 5vw;
                top: 0;
            }

            h2 {
                color: $bg;
                margin-bottom: 20px;
            }

            a {
                color: $bg;
            }
        }
    }

    .ferienwohnung {
        flex-direction: row-reverse;

        @include mobile {
            flex-direction: column;
        }

        .left {

            p {
                margin-left: 0;
                margin-right: 5vw;

                @include mobile {
                    margin-left: 5vw;
                }
            }
        }

        .right {
            left: 30vw;

            @include mobile {
                left: 5vw;
            }
        }
    }
}