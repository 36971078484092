@import "../../style.scss";

.footer {
    margin-top: 15vh;

    .container {
        color: $textColor;
        background: $bgLight;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-bottom: 5vh;
        gap: 50px;

        .top {
            display: flex;
            flex-direction: column;
            align-items: center;

            .logo {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: auto;
                color: inherit;
                margin-bottom: 50px;
                transition: 0.2s ease-in-out;
                text-transform: inherit;
                text-align: center;

                img {
                    height: 100px;
                }
            }

            .links {
                display: flex;

                @include mobile {
                    flex-direction: column;
                }

                span {
                    display: flex;
                    transition: 0.2s ease-in-out;
                    color: $accent;

                    a {
                        text-transform: initial;
                    }

                    @include mobile {
                        text-align: center;
                        justify-content: center;
                    }

                    &:not(:last-child)::after {
                        content: "|";
                        margin-left: 10px;
                        margin-right: 10px;

                        @include mobile {
                            content: "";
                        }
                    }
                }
            }
        }

        .bottom {
            display: flex;
            justify-content: center;
            gap: 50px;

            @include mobile {
                flex-direction: column;
                align-items: center;
                gap: 10px;
            }

            .right {
                display: flex;
                gap: 10px;

                a {
                    text-transform: initial;
                }
            }
        }
    }

    .wrapper {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        width: 90vw;
        max-width: 1920px;
        margin-bottom: 5vh;
        gap: 50px;

        img {
            height: 80px;
            filter: grayscale(1);
            transition: 0.2s ease-in-out;

            &:hover {
                filter: none;
            }
        }
    }

    .wrapperLinks {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 auto;
        width: 90vw;
        max-width: 1920px;
        margin-bottom: 5vh;

        span,
        a {
            margin-right: 10px;

            @include mobile {
                text-align: center;
            }

            &:not(:last-child)::after {
                content: "|";
                margin-left: 10px;

                @include mobile {
                    content: "";
                }
            }
        }
    }
}